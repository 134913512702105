import React from 'react';
import { Form, Segment } from 'semantic-ui-react';

import * as BidderUi from '../components';
import { isNewBidder } from '../helpers';
import { SectionHeader } from '../../../../shared/components';

const BidderEditForm = (props) => {
  const { bidder, isFetching } = props;

  return (
    <Form loading={isFetching}>

      <SectionHeader content="Basics" icon="cubes" />
      <Segment>
        <BidderUi.UserId user={bidder.user} />
        <BidderUi.Name {...props} />
        <BidderUi.CompanyName {...props} />
        <BidderUi.Email {...props} />
        <BidderUi.PriceType {...props} />
        <BidderUi.Mode {...props} />
        <BidderUi.Endpoint {...props} endpointKey="endpoint_production" label="Production endpoint" />
        <BidderUi.Endpoint {...props} endpointKey="endpoint_test" label="Test endpoint" />
        <BidderUi.PipelineType {...props} label="Pipeline Type" />
      </Segment>

      <SectionHeader content="Loopme Proxy" icon="redo" />
      <Segment>
        <BidderUi.LoopmeProxy {...props} />
      </Segment>

      <SectionHeader content="Custom Bid Request Headers" icon="file text outline" />
      <Segment>
        <BidderUi.CustomHeaders {...props} />
      </Segment>

      <SectionHeader content="Throttling" icon="rocket" />
      <Segment>
        <BidderUi.ThrottlingSet {...props} campaignType="static" label="Static campaigns" />
        <BidderUi.ThrottlingSet {...props} campaignType="video" label="Video campaigns" />
      </Segment>

      <SectionHeader content="Campaigns" icon="flag" />
      <Segment>
        <BidderUi.CampaignSet {...props} campaignType="static" label="Static campaigns" />
        <BidderUi.CampaignSet {...props} campaignType="video" label="Video campaigns" />
        <BidderUi.Toggle {...props} label="Has MRAID playable (enables imp[].banner in Rewarded placements)" path="has_mraid_playable" />
      </Segment>

      <SectionHeader content="Transaction Fee Formula" icon="money" />
      <Segment>
        <BidderUi.FormulaSet {...props} />
      </Segment>

      <SectionHeader content="Bid Request Validators" icon="checkmark box" />
      <Segment>
        <BidderUi.BlockConnectionSet {...props} />
        <BidderUi.BlockAppsList {...props} />
        <BidderUi.Toggle
          {...props}
          label="Require User-Agent"
          path="request_validation.require_user_agent"
        />
        <BidderUi.Toggle
          {...props}
          label="Block COPPA requests"
          path="request_validation.blocked_coppa"
        />
        <BidderUi.Toggle
          {...props}
          label="Ghost Bidding enabled"
          path="response_validation.ghost_bids_enabled"
        />
      </Segment>

      <SectionHeader content="Bid Request Processors" icon="setting" />
      <Segment>
        <BidderUi.Toggle
          {...props}
          label="Use iTunes Store Id for app.bundle"
          path="request_processing.itunes_id_as_bundle_id"
        />
        <BidderUi.Toggle
          {...props}
          label="Set imp[].banner.w & imp[].banner.h to standard IAB sizes"
          path="request_processing.require_standard_resolutions"
        />
        <BidderUi.Toggle
          {...props}
          label="Set imp[].video.w & imp[].video.h to standard IAB sizes"
          path="request_processing.require_standard_resolutions_video"
        />
        <BidderUi.Toggle
          {...props}
          label="nurl -> ext.imptrackers"
          path="response_processing.win_url_as_imp_trackers"
        />
      </Segment>

      <SectionHeader content="Custom map imp[].banner.w & imp[].banner.h sizes" icon="setting" />
      <Segment>
        <BidderUi.BannerResizeSet {...props} />
      </Segment>

      <SectionHeader content="Block Publisher Apps Behavior (bapp: [])" icon="setting" />
      <Segment>
        <BidderUi.BlockAppsBehavior {...props} />
        <BidderUi.MaxBlockedApps {...props} />
      </Segment>

      <SectionHeader content="Block Creative Attributes (battr: [])" icon="dont" />
      <Segment>
        <BidderUi.BlockCreativeAttributes
          {...props}
          label="Rewarded Placement - Video - imp[].video.battr"
          path="request_processing.battr.rewarded_video_video_req"
        />
        <BidderUi.BlockCreativeAttributesList
          {...props}
          path="request_processing.battr.rewarded_video_video_req"
        />
        <BidderUi.BlockCreativeAttributes
          {...props}
          label="Rewarded Placement - Playable - imp[].banner.battr"
          path="request_processing.battr.rewarded_video_banner_req"
        />
        <BidderUi.BlockCreativeAttributesList
          {...props}
          path="request_processing.battr.rewarded_video_banner_req"
        />
        <BidderUi.BlockCreativeAttributes
          {...props}
          label="Interstitial Placement - Static (static_interstitial_banner_req) - imp[].banner.battr"
          path="request_processing.battr.static_interstitial_banner_req"
        />
        <BidderUi.BlockCreativeAttributesList
          {...props}
          path="request_processing.battr.static_interstitial_banner_req"
        />
        <BidderUi.BlockCreativeAttributes
          {...props}
          label="Interstitial Placement - Video (video_interstitial_video_req) - imp[].video.battr"
          path="request_processing.battr.video_interstitial_video_req"
        />
        <BidderUi.BlockCreativeAttributesList
          {...props}
          path="request_processing.battr.video_interstitial_video_req"
        />
        <BidderUi.BlockCreativeAttributes
          {...props}
          label="Interstitial Placement - Static or Video (video_interstitial_banner_req) - imp[].banner.battr"
          path="request_processing.battr.video_interstitial_banner_req"
        />
        <BidderUi.BlockCreativeAttributesList
          {...props}
          path="request_processing.battr.video_interstitial_banner_req"
        />
      </Segment>

      <SectionHeader content="Block Creative IDs (bid response validation)" icon="dont" />
      <Segment>
        <BidderUi.BlockedCreatives path="response_validation.blocked_creatives" {...props} />
      </Segment>

      <SectionHeader content="Geo targeting" icon="world" />
      <Segment>
        <BidderUi.CountriesSection {...props} />
      </Segment>

      <SectionHeader content="Debugging (exchange.debug Kafka topic)" icon="bug" />
      <Segment>
        <Form.Group widths="equal">
          <BidderUi.PercentageInput
            {...props}
            label="Log campaign validations"
            path="debug.log_campaign_validations"
          />
          <BidderUi.PercentageInput
            {...props}
            label="Log request on error"
            path="debug.log_request_on_error"
          />
          <BidderUi.PercentageInput
            {...props}
            label="Log response"
            path="debug.log_response"
          />
        </Form.Group>
      </Segment>

      <SectionHeader content="Device Identifier Whitelist" icon="check" />
      <Segment>
        <BidderUi.WhitelistedDeviceIds {...props} />
      </Segment>

      {
        isNewBidder(bidder)
        ? null
        : (
          <div>
            <SectionHeader content="Publisher Configuration" icon="tree" />
            <Segment>
              <Form.Field>
                <BidderUi.DeployPubConfig {...props} />
              </Form.Field>
            </Segment>
          </div>
        )
      }
    </Form>
  );
};

export default BidderEditForm;
