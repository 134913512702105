import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import urlParse from 'url-parse';
import { formatEndpoint } from '../helpers';


class LoopmeProxy extends React.Component {
  constructor(props) {
    super(props);
    const { bidder: { proxy_settings: proxySettings = {}} } = props;
    this.state = {
      enabled: proxySettings.enabled,
      endpoint: formatEndpoint(proxySettings.endpoint),
      sampleRate: proxySettings.sample_rate,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { bidder: { proxy_settings: proxySettings = {}} } = this.props;
    const { bidder: { proxy_settings: nextProxySettings }} = nextProps;
    if (proxySettings && nextProxySettings) {
      const endpoint = formatEndpoint(proxySettings.endpoint)
      const nextEndpoint = formatEndpoint(nextProxySettings.endpoint)
      if (
        proxySettings.sample_rate !== nextProxySettings.sample_rate ||
        proxySettings.enabled !== nextProxySettings.enabled ||
        endpoint !== nextEndpoint
      ) {
        this.setState({
          enabled: nextProxySettings.enabled,
          endpoint: formatEndpoint(nextProxySettings.endpoint),
          sampleRate: nextProxySettings.sample_rate,
        });
      }
    }
  }

  debouncedSetBidder = debounce(this.props.setBidder, 1000);

  render() {
    const { bidder: { id } } = this.props;

    const {
      enabled,
      endpoint,
      sampleRate
    } = this.state;

    const setEndpoint = (endpoint) => {
      const { hostname, pathname, port, protocol, query } = urlParse(endpoint, false);
      const getPort = Number(port) || 80;

      return {
        hostname: endpoint? hostname : "",
        path: endpoint? pathname + query : "",
        port: protocol === 'https:' ? 443 : getPort,
        protocol,
      };
    }
    
    const onChangeEnabled = (event, ownProps) => {
      this.setState({
        ...this.state,
        enabled: ownProps.checked
      })
      this.props.setBidder(id, {
        proxy_settings: {
          enabled: ownProps.checked,
          sample_rate: sampleRate,
          endpoint: setEndpoint(endpoint)
        }
      })
    }

    const onChangeSampleRate = (event, ownProps) => {
          if (ownProps.value < 0 || ownProps.value > 50) {
            return;
          }
      this.setState({
        ...this.state,
        sample_rate: Number(ownProps.value)
      })
      this.props.setBidder(id, {
        proxy_settings: {
          enabled: enabled,
          sample_rate: Number(ownProps.value),
          endpoint: setEndpoint(endpoint)
        }
      })
    }

    const onChangeEndpoint = (event, ownProps) => {
      this.setState({
        ...this.state,
        endpoint: ownProps.value
      })
      this.debouncedSetBidder(id, {
        proxy_settings: {
          enabled: enabled,
          sample_rate: sampleRate,
          endpoint: setEndpoint(ownProps.value)
        }
      })
    }

    return (
      <div>
        <div style={{textAlign: 'right'}}>
        <Form.Field>
            <Form.Checkbox 
              toggle 
              label="enabled" 
              onChange={onChangeEnabled}
              checked={enabled} 
            />
        </Form.Field>
        </div>
        <Form.Field>
          <label htmlFor={`proxy-endpoint`}>Endpoint</label>
          <Input
            id="proxy-endpoint"
            autoComplete
            placeholder="http://subdomain.bidder.com/path/to/endpoint?filter=lasers"
            type="input"
            disabled={!enabled}
            value={endpoint}
            onChange={onChangeEndpoint}
          />
        </Form.Field>
        <Form.Group widths={"equal"}>
          <Form.Field>
              <label htmlFor={`sample-rate`}>Test cohort</label>
              <Input
                  id="sample-rate"
                  autoComplete
                  className="number"
                  label={{ content: '%' }}
                  labelPosition="right"
                  max={50}
                  min={0}
                  type="number"
                  disabled={!enabled}
                  onChange={onChangeSampleRate}
                  value={sampleRate}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor={`ctrl-rate`}>Control cohort</label>
              <Input
                  id="ctrl-rate"
                  autoComplete
                  className="number"
                  label={{ content: '%' }}
                  labelPosition="right"
                  max={50}
                  min={0}
                  type="number"
                  disabled={true}
                  value={sampleRate}
              />
          </Form.Field>
        </Form.Group>
      </div>
    );
  }
}

export default LoopmeProxy;

LoopmeProxy.defaultProps = {
  bidder: {},
  setBidder: () => {},
};

LoopmeProxy.propTypes = {
  bidder: PropTypes.shape({}),
  setBidder: PropTypes.func,
};
