export const BIDDER_MODES = {
  prod: { endpoint: 'endpoint_production', text: 'Production' },
  test: { endpoint: 'endpoint_test', text: 'Test' },
  default: { endpoint: 'endpoint_production', text: 'Unknown' },
};

export const PRICE_TYPES = {
  'first-price': { value: 'first-price', text: 'First price' },
  'second-price': { value: 'second-price', text: 'Second price' },
  default: { value: 'first-price', text: 'First price' },
};

export const PIPELINE_OPTIONS = [
  { text: 'Open RTB', value: 'open_rtb' },
];

export const BIDDER_DEFAULTS = {
  name: '',
  email: '',
  has_mraid_playable: false,
  mode: 'prod',
  price_type: 'first-price',
  type: 'dsp',
  user: '',
  custom_headers: {},
  campaigns: {
    static: {
      android: { campaign_id: '', enabled: false },
      ios: { campaign_id: '', enabled: false },
    },
    video: {
      android: { campaign_id: '', enabled: false },
      ios: { campaign_id: '', enabled: false },
    },
  },
  debug: {
    log_campaign_validations: 0.0,
    log_request_on_error: 0.0,
    log_response: 0.0,
  },
  endpoint: {
    host: '',
    path: '',
    port: 80,
    protocol: 'http:',
  },
  endpoint_test: {
    host: '',
    path: '',
    port: 80,
    protocol: 'http:',
  },
  endpoint_production: {
    host: '',
    path: '',
    port: 80,
    protocol: 'http:',
  },
  proxy_settings: {
    enabled: false,
    endpoint: {
      host: '',
      path: '',
      port: 80,
      protocol: 'http:',
    },
    sample_rate: 0
  },
  request_processing: {
    battr: {
      rewarded_video_banner_req: [],
      rewarded_video_video_req: [],
      static_interstitial_banner_req: [],
      video_interstitial_banner_req: [],
      video_interstitial_video_req: [],
    },
    bapp_behavior: 'none',
    change_banner_size: {},
    itunes_id_as_bundle_id: true,
    max_blocked_apps: 50,
    require_standard_resolutions: true,
    require_standard_resolutions_video: true,
    test_mode: false,
  },
  request_validation: {
    allowed_countries: ['US'],
    blocked_apps: { android: [], ios: [] },
    blocked_connection_types: [],
    blocked_coppa: true,
    blocked_countries: [],
    require_user_agent: true,
    granular_throttling: {
      static: {
        android: 0,
        ios: 0,
      },
      video: {
        android: 0,
        ios: 0,
      },
    },
  },
  response_processing: { win_url_as_imp_trackers: true },
  response_validation: { ghost_bids_enabled: true },
  request_response_pipeline_type: 'open_rtb',
  price_formula: {
    dollars: 0.00,
    formula: 'dollars',
    percent: 0.00,
    transaction_fee_shared: false,
  },
};

export const BLOCK_APPS_BEHAVIORS = {
  all: { text: 'All - Send all the blocked apps', value: 'all' },
  limit: { text: 'Limit - Send a limited number of apps', value: 'limit' },
  none: { text: 'None - Do not send the attribute', value: 'none' },
};

export const DEFAULT_BANNER_SIZES = {
  '1024x768': [1024, 768],
  '480x320': [480, 320],
  '768x1024': [768, 1024],
  '320x480': [320, 480],
};
